<template>
  <div class="row justify-center items-start bg-grey-3" style="height: calc(100vh - 83px);">
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section class="row justify-end">
        <q-input outlined label="Cari Siswa" style="max-width:300px;">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section horizontal>
        <q-card-section class="column q-gutter-md q-pt-none" style="min-width:300px">
          <q-select
            outlined
            stack-label
            v-model="selMapel"
            label="Mata Pelajaran"
            :options="mapel"
            @input="getRombelList"
          />
          <q-select outlined stack-label v-model="selKelas" label="Kelas" :options="kelas" />
          <q-select
            outlined
            stack-label
            v-model="selKategori"
            label="Kategori Penilaian"
            :options="kategori"
          />
          <q-btn
            outline
            color="primary"
            label="TAMPILKAN"
            @click="fetchData"
            :disable="selKelas == null || selKategori  == null || selMapel  == null"
          ></q-btn>
          <q-btn filled color="primary" label="SIMPAN"></q-btn>
        </q-card-section>
        <q-markup-table
          flat
          bordered
          class="stickyTable bg-indigo-1 full-width"
          style="height: calc(100vh - 267px);"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr v-if="selKategori == 'TUGAS' || selKategori=='UH' ">
              <th rowspan="2">no</th>
              <th rowspan="2">nama</th>
              <th :colspan="tugas + 2">
                <q-select
                  class="bg-white"
                  filled
                  outlined
                  stack-label
                  dense
                  v-model="selKD"
                  label="Kompetensi Dasar"
                  :options="kd"
                  @input="getPanjangTugas"
                />
              </th>
            </tr>
            <tr v-if="selKategori == 'TUGAS' || selKategori=='UH'">
              <template v-if="selKategori=='TUGAS'">
                <th v-for="(el,idx) in tugas" :key="idx">
                  Tugas {{idx+1}}
                  <q-btn flat dense size="xs" icon="edit"></q-btn>
                </th>
              </template>
              <template v-if="selKategori=='UH'">
                <th v-for="(el,idx) in tugas" :key="idx">
                  UH {{idx+1}}
                  <q-btn flat dense size="xs" icon="edit"></q-btn>
                </th>
              </template>
              <th>
                <q-btn flat dense icon="add" @click="tambahTugas"></q-btn>
                <q-btn flat dense icon="horizontal_rule" @click="hapusTugas"></q-btn>
              </th>
              <th>Rata-Rata</th>
            </tr>
            <tr v-else>
              <th>no</th>
              <th>nama</th>
            </tr>
          </thead>
          <tbody v-if="selKategori == 'TUGAS' || selKategori == 'UH' ">
            <tr v-for="(val, i) in siswa" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{val.nama}}</td>
              <template>
                <q-td v-for="(el, idx) in tugas" :key="idx">
                  {{ nilai }}
                  <q-popup-edit :value="nilai" title="Masukan Nilai">
                    <q-input type="number" v-model="nilai" dense autofocus />
                  </q-popup-edit>
                </q-td>
              </template>
              <td></td>
              <td>{{ nilai }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nilai: 0,
      tugas: 3,
      kelas: [],
      selKelas: null,
      mapel: [],
      selMapel: null,
      kategori: ["TUGAS", "UH", "UTS", "UAS"],
      selKategori: "TUGAS",

      kd: [],
      selKD: null,

      siswa: []
    };
  },
  async mounted() {
    await this.getMapelList();
  },
  /* computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  }, */
  methods: {
    async getMapelList() {
      let id_guru = 10;
      await this.$http
        .get(`/penilaian/input_nilai/getmapel/${id_guru}`)
        .then(resp => {
          this.mapel = resp.data;
        });
    },
    async getRombelList() {
      let id_guru = 10;
      this.selKelas = null;
      this.kelas = [];
      await this.$http
        .get(
          `/penilaian/input_nilai/getkelas/${this.selMapel.value}/${id_guru}`
        )
        .then(resp => {
          this.kelas = resp.data;
        });
    },
    async fetchData() {
      if (this.selKategori == "TUGAS" || this.selKategori == "UH") {
        await this.$http
          .get(`/penilaian/input_nilai/getkd/${this.selMapel.value}`)
          .then(resp => {
            this.kd = resp.data;
          });
      }

      await this.$http
        .get(`/penilaian/input_nilai/getsiswa/${this.selKelas.value}`)
        .then(resp => {
          this.siswa = resp.data;
        });
      await this.checkNilaiAkademik();
    },
    async getPanjangTugas() {
      //melihat jumlah tugas yang sudah ada di db
      await this.$http
        .get(
          `/penilaian/input_nilai/getlen/${this.siswa[0].id_nilai_akademik}/${this.selKD.value}/${this.selKategori}`
        )
        .then(resp => {
          this.tugas = parseInt(resp.data.panjang);
          this.$forceUpdate();
        });
    },
    async tambahTugas() {
      await this.$http
        .post(
          `/penilaian/input_nilai/tambah_tugas/${this.selKategori}/${this.selKD.value}`,
          this.siswa
        )
        .then(resp => {
          this.getPanjangTugas();
        });
    },
    async hapusTugas() {
      await this.$http
        .post(
          `/penilaian/input_nilai/hapus_tugas/${this.selKategori}/${this.selKD.value}`,
          this.siswa
        )
        .then(resp => {});
    },
    async checkNilaiAkademik() {
      await this.$http
        .post(
          `/penilaian/input_nilai/ceknilaiakademik/${this.selKelas.id_guru_mapel}`,
          this.siswa
        )
        .then(resp => {});

      await this.$http
        .post(
          `/penilaian/input_nilai/getid_nilai/${this.selKelas.id_guru_mapel}`,
          this.siswa
        )
        .then(resp => {
          let cpy = JSON.parse(JSON.stringify(this.siswa));
          for (let i in resp.data) {
            cpy[i].id_nilai_akademik = resp.data[i];
          }
          this.siswa = cpy;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
